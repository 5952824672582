
@font-face {
  font-family: 'Space-r';
  src: url(./assets/fonts/SpaceMono-Regular.ttf);
}
@font-face {
  font-family: 'Space-b';
  src: url(./assets/fonts/SpaceMono-Bold.ttf);
}

@font-face {
  font-family: 'Roboto-b';
  src: url(./assets/fonts/Roboto-Bold.ttf);
}

@font-face {
  font-family: 'Roboto-l';
  src: url(./assets/fonts/Roboto-Light.ttf);
}

@font-face {
  font-family: 'Roboto-m';
  src: url(./assets/fonts/Roboto-Medium.ttf);
}

@font-face {
  font-family: 'Roboto-r';
  src: url(./assets/fonts/Roboto-Regular.ttf);
}

@font-face {
  font-family: 'Roboto-t';
  src: url(./assets/fonts/Roboto-Thin.ttf);
}

@font-face {
  font-family: 'Gotham-r';
  src: url(./assets/fonts/Gotham-Black-Regular.ttf);
}

html{
  font-size: 8.2px;
}
@media (min-width: 1560px){
  html{
    font-size: 8.8px;
  }
}
@media (min-width: 1680px){
  html{
    font-size: 9.4px;
  }
}
@media (min-width: 1920px){
  html{
    font-size: 10px;
  }}
@media (max-width: 600px){
  html{
    font-size: 10px;
  }
}

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Roboto-r;
  color: #050000;
  font-size: 1.6rem;
}

.ReactModal__Html--open,
.ReactModal__Body--open {
  overflow: hidden;   
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.pac-container {
  box-shadow: none;
  border: none;
  /* margin-top: 2px; */
  overflow: visible;
  position: absolute;
  z-index: 11111111111111;
}
.pac-item:first-child {
  border-top: none;
}
.pac-item {
  margin-left: -2px;
  background: #ffffff;
  padding: 0 4px 0 2px;
  line-height: 28px;
  font-size: 1.2rem;
  cursor: pointer;
}

.hdpi .pac-icon  {
  background-image: url('./assets/signup/location.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 14px;
}

.pac-item-selected > .pac-icon {
  background-image: url('./assets/signup/locationActive.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 14px;
  
}

.pac-logo:after {
  display: none;
}


a{
  text-decoration: none;
}

