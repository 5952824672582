
body {margin-top: 0px;margin-left: 0px;}

#page_1 {position:relative; overflow: hidden;margin: 99px 0px 12px 96px;padding: 0px;border: none;width: 698px;}
#page_1 #id1_1 {border:none;margin: 0px 0px 0px 0px;padding: 0px;border:none;width: 698px;overflow: hidden;}
#page_1 #id1_2 {border:none;margin: 126px 0px 0px 165px;padding: 0px;border:none;width: 533px;overflow: hidden;}





#page_2 {position:relative; overflow: hidden;margin: 107px 0px 12px 96px;padding: 0px;border: none;width: 698px;}
#page_2 #id2_1 {border:none;margin: 0px 0px 0px 0px;padding: 0px;border:none;width: 698px;overflow: hidden;}
#page_2 #id2_2 {border:none;margin: 83px 0px 0px 165px;padding: 0px;border:none;width: 533px;overflow: hidden;}





#page_3 {position:relative; overflow: hidden;margin: 107px 0px 12px 96px;padding: 0px;border: none;width: 698px;}
#page_3 #id3_1 {border:none;margin: 0px 0px 0px 0px;padding: 0px;border:none;width: 698px;overflow: hidden;}
#page_3 #id3_2 {border:none;margin: 109px 0px 0px 165px;padding: 0px;border:none;width: 533px;overflow: hidden;}





#page_4 {position:relative; overflow: hidden;margin: 107px 0px 12px 96px;padding: 0px;border: none;width: 698px;}





#page_5 {position:relative; overflow: hidden;margin: 107px 0px 12px 96px;padding: 0px;border: none;width: 698px;}
#page_5 #id5_1 {border:none;margin: 0px 0px 0px 0px;padding: 0px;border:none;width: 698px;overflow: hidden;}
#page_5 #id5_2 {border:none;margin: 104px 0px 0px 165px;padding: 0px;border:none;width: 533px;overflow: hidden;}





#page_6 {position:relative; overflow: hidden;margin: 107px 0px 12px 96px;padding: 0px;border: none;width: 698px;}





#page_7 {position:relative; overflow: hidden;margin: 99px 0px 12px 96px;padding: 0px;border: none;width: 698px;}
#page_7 #id7_1 {border:none;margin: 0px 0px 0px 0px;padding: 0px;border:none;width: 698px;overflow: hidden;}
#page_7 #id7_2 {border:none;margin: 98px 0px 0px 165px;padding: 0px;border:none;width: 533px;overflow: hidden;}





#page_8 {position:relative; overflow: hidden;margin: 99px 0px 12px 96px;padding: 0px;border: none;width: 698px;}
#page_8 #id8_1 {border:none;margin: 0px 0px 0px 0px;padding: 0px;border:none;width: 698px;overflow: hidden;}
#page_8 #id8_2 {border:none;margin: 138px 0px 0px 165px;padding: 0px;border:none;width: 533px;overflow: hidden;}





#page_9 {position:relative; overflow: hidden;margin: 99px 0px 12px 96px;padding: 0px;border: none;width: 698px;}
#page_9 #id9_1 {border:none;margin: 0px 0px 0px 0px;padding: 0px;border:none;width: 698px;overflow: hidden;}
#page_9 #id9_2 {border:none;margin: 85px 0px 0px 165px;padding: 0px;border:none;width: 533px;overflow: hidden;}





#page_10 {position:relative; overflow: hidden;margin: 98px 0px 12px 96px;padding: 0px;border: none;width: 698px;}
#page_10 #id10_1 {border:none;margin: 0px 0px 0px 0px;padding: 0px;border:none;width: 698px;overflow: hidden;}
#page_10 #id10_2 {border:none;margin: 85px 0px 0px 165px;padding: 0px;border:none;width: 533px;overflow: hidden;}





#page_11 {position:relative; overflow: hidden;margin: 94px 0px 12px 96px;padding: 0px;border: none;width: 698px;}
#page_11 #id11_1 {border:none;margin: 0px 0px 0px 0px;padding: 0px;border:none;width: 698px;overflow: hidden;}
#page_11 #id11_2 {border:none;margin: 90px 0px 0px 165px;padding: 0px;border:none;width: 533px;overflow: hidden;}





#page_12 {position:relative; overflow: hidden;margin: 96px 0px 12px 96px;padding: 0px;border: none;width: 698px;}
#page_12 #id12_1 {border:none;margin: 0px 0px 0px 0px;padding: 0px;border:none;width: 698px;overflow: hidden;}
#page_12 #id12_2 {border:none;margin: 94px 0px 0px 165px;padding: 0px;border:none;width: 533px;overflow: hidden;}





#page_13 {position:relative; overflow: hidden;margin: 99px 0px 12px 96px;padding: 0px;border: none;width: 698px;}
#page_13 #id13_1 {border:none;margin: 0px 0px 0px 0px;padding: 0px;border:none;width: 698px;overflow: hidden;}
#page_13 #id13_2 {border:none;margin: 780px 0px 0px 165px;padding: 0px;border:none;width: 533px;overflow: hidden;}





.ft0{font: bold 15px 'Calibri';text-decoration: underline;line-height: 18px;}
.ft1{font: bold 15px 'Calibri';line-height: 25px;}
.ft2{font: 15px 'Calibri';line-height: 25px;}
.ft3{font: 15px 'Calibri';line-height: 24px;}
.ft4{font: 15px 'Calibri';line-height: 18px;}
.ft5{font: 15px 'Calibri';margin-left: 13px;line-height: 24px;}
.ft6{font: bold 15px 'Calibri';line-height: 24px;}
.ft7{font: 15px 'Calibri';margin-left: 13px;line-height: 23px;}
.ft8{font: 15px 'Calibri';line-height: 23px;}
.ft9{font: 15px 'Calibri';margin-left: 13px;line-height: 21px;}
.ft10{font: 15px 'Calibri';line-height: 21px;}
.ft11{font: 14px 'Calibri';margin-left: 13px;line-height: 24px;}
.ft12{font: 14px 'Calibri';line-height: 24px;}
.ft13{font: 8px 'Calibri';line-height: 10px;}
.ft14{font: 15px 'Calibri';margin-left: 13px;line-height: 18px;}
.ft15{font: bold 15px 'Calibri';line-height: 21px;}
.ft16{font: bold 15px 'Calibri';text-decoration: underline;line-height: 21px;}
.ft17{font: 15px 'Calibri';text-decoration: underline;margin-left: 13px;line-height: 18px;}
.ft18{font: 15px 'Calibri';text-decoration: underline;line-height: 18px;}
.ft19{font: 15px 'Calibri';margin-left: 7px;line-height: 21px;}
.ft20{font: 15px 'Calibri';margin-left: 7px;line-height: 23px;}
.ft21{font: 15px 'Calibri';margin-left: 7px;line-height: 24px;}
.ft22{font: 15px 'Calibri';margin-left: 10px;line-height: 18px;}
.ft23{font: 15px 'Times New Roman';line-height: 17px;}
.ft24{font: 15px 'Calibri';margin-left: 27px;line-height: 18px;}
.ft25{font: 14px 'Calibri';margin-left: 27px;line-height: 25px;}
.ft26{font: 14px 'Calibri';line-height: 25px;}
.ft27{font: 15px 'Calibri';margin-left: 27px;line-height: 24px;}
.ft28{font: 15px 'Calibri';margin-left: 27px;line-height: 25px;}
.ft29{font: 15px 'Calibri';margin-left: 30px;line-height: 25px;}
.ft30{font: 15px 'Calibri';text-decoration: underline;margin-left: 5px;line-height: 18px;}
.ft31{font: 15px 'Calibri';margin-left: 4px;line-height: 24px;}
.ft32{font: 14px 'Calibri';margin-left: 4px;line-height: 23px;}
.ft33{font: 14px 'Calibri';line-height: 23px;}
.ft34{font: 15px 'Calibri';margin-left: 4px;line-height: 23px;}
.ft35{font: 15px 'Calibri';margin-left: 4px;line-height: 21px;}
.ft36{font: 15px 'Calibri';margin-left: 6px;line-height: 24px;}
.ft37{font: 15px 'Calibri';margin-left: 5px;line-height: 24px;}
.ft38{font: 15px 'Calibri';margin-left: 5px;line-height: 18px;}
.ft39{font: bold 15px 'Calibri';margin-left: 5px;line-height: 21px;}
.ft40{font: bold 14px 'Calibri';margin-left: 5px;line-height: 23px;}
.ft41{font: 14px 'Calibri';margin-left: 5px;line-height: 24px;}
.ft42{font: 14px 'Calibri';text-decoration: underline;margin-left: 5px;line-height: 27px;}
.ft43{font: 14px 'Calibri';line-height: 27px;}
.ft44{font: 9px 'Calibri';line-height: 17px;position: relative; bottom: 5px;}
.ft45{font: 15px 'Calibri';margin-left: 5px;line-height: 21px;}
.ft46{font: 15px 'Calibri';margin-left: 5px;line-height: 25px;}
.ft47{font: 14px 'Calibri';margin-left: 5px;line-height: 25px;}
.ft48{font: bold 15px 'Calibri';line-height: 18px;}
.ft49{font: 14px 'Calibri';margin-left: 5px;line-height: 21px;}
.ft50{font: 14px 'Calibri';line-height: 21px;}
.ft51{font: bold 14px 'Calibri';line-height: 24px;}
.ft52{font: 14px 'Calibri';margin-left: 5px;line-height: 22px;}
.ft53{font: 14px 'Calibri';line-height: 22px;}
.ft54{font: 15px 'Calibri';line-height: 22px;}
.ft55{font: 14px 'Calibri';line-height: 17px;}
.ft56{font: 15px 'Calibri';margin-left: 3px;line-height: 25px;}
.ft57{font: 14px 'Calibri';margin-left: 5px;line-height: 26px;}
.ft58{font: 14px 'Calibri';line-height: 26px;}
.ft59{font: 15px 'Calibri';margin-left: 5px;line-height: 26px;}
.ft60{font: 15px 'Calibri';line-height: 26px;}
.ft61{font: 15px 'Calibri';margin-left: 5px;line-height: 22px;}
.ft62{font: 15px 'Calibri';margin-left: 8px;line-height: 21px;}

.p0{text-align: left;padding-left: 235px;margin-top: 0px;margin-bottom: 0px;}
.p1{text-align: justify;padding-right: 96px;margin-top: 15px;margin-bottom: 0px;}
.p2{text-align: justify;padding-right: 96px;margin-top: 24px;margin-bottom: 0px;}
.p3{text-align: justify;padding-right: 96px;margin-top: 23px;margin-bottom: 0px;}
.p4{text-align: left;margin-top: 27px;margin-bottom: 0px;}
.p5{text-align: justify;padding-left: 24px;padding-right: 96px;margin-top: 26px;margin-bottom: 0px;text-indent: -24px;}
.p6{text-align: justify;padding-left: 24px;padding-right: 91px;margin-top: 6px;margin-bottom: 0px;text-indent: -24px;}
.p7{text-align: left;padding-left: 24px;padding-right: 96px;margin-top: 8px;margin-bottom: 0px;text-indent: -24px;}
.p8{text-align: left;padding-left: 24px;padding-right: 96px;margin-top: 9px;margin-bottom: 0px;text-indent: -24px;}
.p9{text-align: justify;padding-left: 24px;padding-right: 91px;margin-top: 9px;margin-bottom: 0px;text-indent: -24px;}
.p10{text-align: left;margin-top: 0px;margin-bottom: 0px;}
.p11{text-align: left;padding-left: 3px;margin-top: 0px;margin-bottom: 0px;}
.p12{text-align: left;margin-top: 8px;margin-bottom: 0px;}
.p13{text-align: left;margin-top: 7px;margin-bottom: 0px;}
.p14{text-align: left;padding-left: 24px;padding-right: 96px;margin-top: 8px;margin-bottom: 0px;}
.p15{text-align: justify;padding-left: 24px;padding-right: 96px;margin-top: 9px;margin-bottom: 0px;text-indent: -24px;}
.p16{text-align: left;margin-top: 6px;margin-bottom: 0px;}
.p17{text-align: left;padding-left: 53px;padding-right: 96px;margin-top: 8px;margin-bottom: 0px;text-indent: -29px;}
.p18{text-align: justify;padding-left: 53px;padding-right: 96px;margin-top: 9px;margin-bottom: 0px;text-indent: -29px;}
.p19{text-align: justify;padding-left: 53px;padding-right: 96px;margin-top: 8px;margin-bottom: 0px;text-indent: -29px;}
.p20{text-align: justify;padding-left: 53px;padding-right: 96px;margin-top: 6px;margin-bottom: 0px;text-indent: -29px;}
.p21{text-align: left;padding-left: 24px;margin-top: 7px;margin-bottom: 0px;}
.p22{text-align: left;padding-left: 48px;margin-top: 4px;margin-bottom: 0px;}
.p23{text-align: left;padding-left: 48px;margin-top: 7px;margin-bottom: 0px;}
.p24{text-align: justify;padding-left: 82px;padding-right: 91px;margin-top: 4px;margin-bottom: 0px;text-indent: -34px;}
.p25{text-align: justify;padding-left: 82px;padding-right: 91px;margin-top: 3px;margin-bottom: 0px;text-indent: -34px;}
.p26{text-align: left;padding-left: 82px;padding-right: 96px;margin-top: 0px;margin-bottom: 0px;}
.p27{text-align: left;padding-left: 82px;padding-right: 96px;margin-top: 5px;margin-bottom: 0px;text-indent: -34px;}
.p28{text-align: justify;padding-left: 82px;padding-right: 96px;margin-top: 0px;margin-bottom: 0px;text-indent: -34px;}
.p29{text-align: justify;padding-left: 82px;padding-right: 96px;margin-top: 4px;margin-bottom: 0px;text-indent: -34px;}
.p30{text-align: left;padding-left: 48px;margin-top: 6px;margin-bottom: 0px;}
.p31{text-align: left;padding-left: 82px;padding-right: 96px;margin-top: 7px;margin-bottom: 0px;text-indent: -34px;}
.p32{text-align: left;padding-left: 24px;margin-top: 8px;margin-bottom: 0px;}
.p33{text-align: justify;padding-left: 53px;padding-right: 96px;margin-top: 2px;margin-bottom: 0px;text-indent: -34px;}
.p34{text-align: justify;padding-left: 53px;padding-right: 91px;margin-top: 17px;margin-bottom: 0px;text-indent: -34px;}
.p35{text-align: left;padding-left: 48px;margin-top: 3px;margin-bottom: 0px;}
.p36{text-align: left;padding-left: 48px;margin-top: 8px;margin-bottom: 0px;}
.p37{text-align: justify;padding-left: 53px;padding-right: 96px;margin-top: 0px;margin-bottom: 0px;text-indent: -34px;}
.p38{text-align: left;padding-left: 53px;padding-right: 96px;margin-top: 8px;margin-bottom: 0px;text-indent: -34px;}
.p39{text-align: justify;padding-left: 53px;padding-right: 96px;margin-top: 9px;margin-bottom: 0px;text-indent: -34px;}
.p40{text-align: left;padding-left: 53px;padding-right: 96px;margin-top: 6px;margin-bottom: 0px;text-indent: -34px;}
.p41{text-align: left;padding-left: 53px;padding-right: 96px;margin-top: 10px;margin-bottom: 0px;text-indent: -34px;}
.p42{text-align: justify;padding-left: 53px;padding-right: 96px;margin-top: 7px;margin-bottom: 0px;text-indent: -34px;}
.p43{text-align: left;padding-left: 53px;padding-right: 96px;margin-top: 11px;margin-bottom: 0px;text-indent: -34px;}
.p44{text-align: justify;padding-left: 53px;padding-right: 96px;margin-top: 7px;margin-bottom: 0px;text-indent: -44px;}
.p45{text-align: justify;padding-left: 53px;padding-right: 96px;margin-top: 9px;margin-bottom: 0px;text-indent: -44px;}
.p46{text-align: justify;padding-left: 24px;padding-right: 96px;margin-top: 11px;margin-bottom: 0px;text-indent: -24px;}
.p47{text-align: left;padding-left: 165px;margin-top: 79px;margin-bottom: 0px;}
.p48{text-align: left;padding-left: 24px;padding-right: 96px;margin-top: 7px;margin-bottom: 0px;text-indent: -24px;}
.p49{text-align: justify;padding-left: 24px;padding-right: 91px;margin-top: 7px;margin-bottom: 0px;text-indent: -24px;}
.p50{text-align: justify;padding-left: 24px;padding-right: 96px;margin-top: 7px;margin-bottom: 0px;text-indent: -24px;}
.p51{text-align: justify;padding-left: 24px;padding-right: 96px;margin-top: 6px;margin-bottom: 0px;text-indent: -24px;}
.p52{text-align: left;padding-left: 24px;padding-right: 96px;margin-top: 0px;margin-bottom: 0px;text-indent: -24px;}
.p53{text-align: justify;padding-left: 24px;padding-right: 96px;margin-top: 4px;margin-bottom: 0px;text-indent: -24px;}
.p54{text-align: justify;padding-left: 24px;padding-right: 91px;margin-top: 12px;margin-bottom: 0px;text-indent: -24px;}
.p55{text-align: justify;padding-left: 24px;padding-right: 96px;margin-top: 1px;margin-bottom: 0px;text-indent: -24px;}
.p56{text-align: justify;padding-left: 24px;padding-right: 91px;margin-top: 14px;margin-bottom: 0px;text-indent: -24px;}
.p57{text-align: left;margin-top: 23px;margin-bottom: 0px;}
.p58{text-align: left;margin-top: 19px;margin-bottom: 0px;}
.p59{text-align: justify;padding-left: 24px;padding-right: 96px;margin-top: 20px;margin-bottom: 0px;text-indent: -24px;}
.p60{text-align: left;padding-left: 165px;margin-top: 164px;margin-bottom: 0px;}
.p61{text-align: left;padding-left: 24px;padding-right: 91px;margin-top: 20px;margin-bottom: 0px;text-indent: -24px;}
.p62{text-align: justify;padding-left: 24px;padding-right: 91px;margin-top: 11px;margin-bottom: 0px;text-indent: -24px;}
.p63{text-align: left;margin-top: 18px;margin-bottom: 0px;}
.p64{text-align: justify;padding-left: 24px;padding-right: 96px;margin-top: 16px;margin-bottom: 0px;text-indent: -24px;}
.p65{text-align: justify;padding-left: 24px;padding-right: 96px;margin-top: 19px;margin-bottom: 0px;text-indent: -24px;}
.p66{text-align: left;padding-left: 24px;padding-right: 96px;margin-top: 0px;margin-bottom: 0px;}
.p67{text-align: left;margin-top: 24px;margin-bottom: 0px;}
.p68{text-align: justify;padding-left: 24px;padding-right: 91px;margin-top: 16px;margin-bottom: 0px;text-indent: -24px;}
.p69{text-align: justify;padding-left: 24px;padding-right: 96px;margin-top: 13px;margin-bottom: 0px;text-indent: -24px;}
.p70{text-align: justify;padding-left: 24px;padding-right: 96px;margin-top: 14px;margin-bottom: 0px;text-indent: -24px;}
.p71{text-align: justify;padding-left: 24px;padding-right: 91px;margin-top: 13px;margin-bottom: 0px;text-indent: -24px;}
.p72{text-align: left;padding-left: 24px;padding-right: 96px;margin-top: 13px;margin-bottom: 0px;text-indent: -24px;}
.p73{text-align: justify;padding-left: 24px;padding-right: 96px;margin-top: 17px;margin-bottom: 0px;text-indent: -24px;}
.p74{text-align: justify;padding-left: 24px;padding-right: 91px;margin-top: 20px;margin-bottom: 0px;text-indent: -24px;}
.p75{text-align: justify;padding-left: 24px;padding-right: 91px;margin-top: 2px;margin-bottom: 0px;text-indent: -24px;}
.p76{text-align: left;margin-top: 29px;margin-bottom: 0px;}
.p77{text-align: justify;padding-left: 24px;padding-right: 91px;margin-top: 15px;margin-bottom: 0px;text-indent: -24px;}
.p78{text-align: justify;padding-left: 24px;padding-right: 91px;margin-top: 0px;margin-bottom: 0px;}
.p79{text-align: justify;padding-left: 24px;padding-right: 96px;margin-top: 10px;margin-bottom: 0px;}
.p80{text-align: left;margin-top: 30px;margin-bottom: 0px;}
.p81{text-align: left;margin-top: 25px;margin-bottom: 0px;}
.p82{text-align: justify;padding-left: 24px;padding-right: 96px;margin-top: 15px;margin-bottom: 0px;text-indent: -24px;}
.p83{text-align: left;margin-top: 28px;margin-bottom: 0px;}
.p84{text-align: justify;padding-left: 24px;padding-right: 96px;margin-top: 0px;margin-bottom: 0px;}
.p85{text-align: left;margin-top: 21px;margin-bottom: 0px;}
.p86{text-align: left;padding-left: 24px;padding-right: 91px;margin-top: 7px;margin-bottom: 0px;text-indent: -24px;}
.p87{text-align: left;margin-top: 22px;margin-bottom: 0px;}
.p88{text-align: left;padding-left: 24px;padding-right: 96px;margin-top: 19px;margin-bottom: 0px;text-indent: -24px;}
.p89{text-align: left;margin-top: 58px;margin-bottom: 0px;}

body {margin-top: 0px;margin-left: 0px;}

#page_1 {position:relative; overflow: hidden;margin: 99px 0px 12px 96px;padding: 0px;border: none;width: 698px;}
#page_1 #id1_1 {border:none;margin: 0px 0px 0px 0px;padding: 0px;border:none;width: 698px;overflow: hidden;}
#page_1 #id1_2 {border:none;margin: 126px 0px 0px 165px;padding: 0px;border:none;width: 533px;overflow: hidden;}





#page_2 {position:relative; overflow: hidden;margin: 107px 0px 12px 96px;padding: 0px;border: none;width: 698px;}
#page_2 #id2_1 {border:none;margin: 0px 0px 0px 0px;padding: 0px;border:none;width: 698px;overflow: hidden;}
#page_2 #id2_2 {border:none;margin: 83px 0px 0px 165px;padding: 0px;border:none;width: 533px;overflow: hidden;}





#page_3 {position:relative; overflow: hidden;margin: 107px 0px 12px 96px;padding: 0px;border: none;width: 698px;}
#page_3 #id3_1 {border:none;margin: 0px 0px 0px 0px;padding: 0px;border:none;width: 698px;overflow: hidden;}
#page_3 #id3_2 {border:none;margin: 109px 0px 0px 165px;padding: 0px;border:none;width: 533px;overflow: hidden;}





#page_4 {position:relative; overflow: hidden;margin: 107px 0px 12px 96px;padding: 0px;border: none;width: 698px;}





#page_5 {position:relative; overflow: hidden;margin: 107px 0px 12px 96px;padding: 0px;border: none;width: 698px;}
#page_5 #id5_1 {border:none;margin: 0px 0px 0px 0px;padding: 0px;border:none;width: 698px;overflow: hidden;}
#page_5 #id5_2 {border:none;margin: 104px 0px 0px 165px;padding: 0px;border:none;width: 533px;overflow: hidden;}





#page_6 {position:relative; overflow: hidden;margin: 107px 0px 12px 96px;padding: 0px;border: none;width: 698px;}





#page_7 {position:relative; overflow: hidden;margin: 99px 0px 12px 96px;padding: 0px;border: none;width: 698px;}
#page_7 #id7_1 {border:none;margin: 0px 0px 0px 0px;padding: 0px;border:none;width: 698px;overflow: hidden;}
#page_7 #id7_2 {border:none;margin: 98px 0px 0px 165px;padding: 0px;border:none;width: 533px;overflow: hidden;}





#page_8 {position:relative; overflow: hidden;margin: 99px 0px 12px 96px;padding: 0px;border: none;width: 698px;}
#page_8 #id8_1 {border:none;margin: 0px 0px 0px 0px;padding: 0px;border:none;width: 698px;overflow: hidden;}
#page_8 #id8_2 {border:none;margin: 138px 0px 0px 165px;padding: 0px;border:none;width: 533px;overflow: hidden;}





#page_9 {position:relative; overflow: hidden;margin: 99px 0px 12px 96px;padding: 0px;border: none;width: 698px;}
#page_9 #id9_1 {border:none;margin: 0px 0px 0px 0px;padding: 0px;border:none;width: 698px;overflow: hidden;}
#page_9 #id9_2 {border:none;margin: 85px 0px 0px 165px;padding: 0px;border:none;width: 533px;overflow: hidden;}





#page_10 {position:relative; overflow: hidden;margin: 98px 0px 12px 96px;padding: 0px;border: none;width: 698px;}
#page_10 #id10_1 {border:none;margin: 0px 0px 0px 0px;padding: 0px;border:none;width: 698px;overflow: hidden;}
#page_10 #id10_2 {border:none;margin: 85px 0px 0px 165px;padding: 0px;border:none;width: 533px;overflow: hidden;}





#page_11 {position:relative; overflow: hidden;margin: 94px 0px 12px 96px;padding: 0px;border: none;width: 698px;}
#page_11 #id11_1 {border:none;margin: 0px 0px 0px 0px;padding: 0px;border:none;width: 698px;overflow: hidden;}
#page_11 #id11_2 {border:none;margin: 90px 0px 0px 165px;padding: 0px;border:none;width: 533px;overflow: hidden;}





#page_12 {position:relative; overflow: hidden;margin: 96px 0px 12px 96px;padding: 0px;border: none;width: 698px;}
#page_12 #id12_1 {border:none;margin: 0px 0px 0px 0px;padding: 0px;border:none;width: 698px;overflow: hidden;}
#page_12 #id12_2 {border:none;margin: 94px 0px 0px 165px;padding: 0px;border:none;width: 533px;overflow: hidden;}





#page_13 {position:relative; overflow: hidden;margin: 99px 0px 12px 96px;padding: 0px;border: none;width: 698px;}
#page_13 #id13_1 {border:none;margin: 0px 0px 0px 0px;padding: 0px;border:none;width: 698px;overflow: hidden;}
#page_13 #id13_2 {border:none;margin: 780px 0px 0px 165px;padding: 0px;border:none;width: 533px;overflow: hidden;}





.ft0{font: bold 15px 'Calibri';text-decoration: underline;line-height: 18px;}
.ft1{font: bold 15px 'Calibri';line-height: 25px;}
.ft2{font: 15px 'Calibri';line-height: 25px;}
.ft3{font: 15px 'Calibri';line-height: 24px;}
.ft4{font: 15px 'Calibri';line-height: 18px;}
.ft5{font: 15px 'Calibri';margin-left: 13px;line-height: 24px;}
.ft6{font: bold 15px 'Calibri';line-height: 24px;}
.ft7{font: 15px 'Calibri';margin-left: 13px;line-height: 23px;}
.ft8{font: 15px 'Calibri';line-height: 23px;}
.ft9{font: 15px 'Calibri';margin-left: 13px;line-height: 21px;}
.ft10{font: 15px 'Calibri';line-height: 21px;}
.ft11{font: 14px 'Calibri';margin-left: 13px;line-height: 24px;}
.ft12{font: 14px 'Calibri';line-height: 24px;}
.ft13{font: 8px 'Calibri';line-height: 10px;}
.ft14{font: 15px 'Calibri';margin-left: 13px;line-height: 18px;}
.ft15{font: bold 15px 'Calibri';line-height: 21px;}
.ft16{font: bold 15px 'Calibri';text-decoration: underline;line-height: 21px;}
.ft17{font: 15px 'Calibri';text-decoration: underline;margin-left: 13px;line-height: 18px;}
.ft18{font: 15px 'Calibri';text-decoration: underline;line-height: 18px;}
.ft19{font: 15px 'Calibri';margin-left: 7px;line-height: 21px;}
.ft20{font: 15px 'Calibri';margin-left: 7px;line-height: 23px;}
.ft21{font: 15px 'Calibri';margin-left: 7px;line-height: 24px;}
.ft22{font: 15px 'Calibri';margin-left: 10px;line-height: 18px;}
.ft23{font: 15px 'Times New Roman';line-height: 17px;}
.ft24{font: 15px 'Calibri';margin-left: 27px;line-height: 18px;}
.ft25{font: 14px 'Calibri';margin-left: 27px;line-height: 25px;}
.ft26{font: 14px 'Calibri';line-height: 25px;}
.ft27{font: 15px 'Calibri';margin-left: 27px;line-height: 24px;}
.ft28{font: 15px 'Calibri';margin-left: 27px;line-height: 25px;}
.ft29{font: 15px 'Calibri';margin-left: 30px;line-height: 25px;}
.ft30{font: 15px 'Calibri';text-decoration: underline;margin-left: 5px;line-height: 18px;}
.ft31{font: 15px 'Calibri';margin-left: 4px;line-height: 24px;}
.ft32{font: 14px 'Calibri';margin-left: 4px;line-height: 23px;}
.ft33{font: 14px 'Calibri';line-height: 23px;}
.ft34{font: 15px 'Calibri';margin-left: 4px;line-height: 23px;}
.ft35{font: 15px 'Calibri';margin-left: 4px;line-height: 21px;}
.ft36{font: 15px 'Calibri';margin-left: 6px;line-height: 24px;}
.ft37{font: 15px 'Calibri';margin-left: 5px;line-height: 24px;}
.ft38{font: 15px 'Calibri';margin-left: 5px;line-height: 18px;}
.ft39{font: bold 15px 'Calibri';margin-left: 5px;line-height: 21px;}
.ft40{font: bold 14px 'Calibri';margin-left: 5px;line-height: 23px;}
.ft41{font: 14px 'Calibri';margin-left: 5px;line-height: 24px;}
.ft42{font: 14px 'Calibri';text-decoration: underline;margin-left: 5px;line-height: 27px;}
.ft43{font: 14px 'Calibri';line-height: 27px;}
.ft44{font: 9px 'Calibri';line-height: 17px;position: relative; bottom: 5px;}
.ft45{font: 15px 'Calibri';margin-left: 5px;line-height: 21px;}
.ft46{font: 15px 'Calibri';margin-left: 5px;line-height: 25px;}
.ft47{font: 14px 'Calibri';margin-left: 5px;line-height: 25px;}
.ft48{font: bold 15px 'Calibri';line-height: 18px;}
.ft49{font: 14px 'Calibri';margin-left: 5px;line-height: 21px;}
.ft50{font: 14px 'Calibri';line-height: 21px;}
.ft51{font: bold 14px 'Calibri';line-height: 24px;}
.ft52{font: 14px 'Calibri';margin-left: 5px;line-height: 22px;}
.ft53{font: 14px 'Calibri';line-height: 22px;}
.ft54{font: 15px 'Calibri';line-height: 22px;}
.ft55{font: 14px 'Calibri';line-height: 17px;}
.ft56{font: 15px 'Calibri';margin-left: 3px;line-height: 25px;}
.ft57{font: 14px 'Calibri';margin-left: 5px;line-height: 26px;}
.ft58{font: 14px 'Calibri';line-height: 26px;}
.ft59{font: 15px 'Calibri';margin-left: 5px;line-height: 26px;}
.ft60{font: 15px 'Calibri';line-height: 26px;}
.ft61{font: 15px 'Calibri';margin-left: 5px;line-height: 22px;}
.ft62{font: 15px 'Calibri';margin-left: 8px;line-height: 21px;}

.p0{text-align: left;padding-left: 235px;margin-top: 0px;margin-bottom: 0px;}
.p1{text-align: justify;padding-right: 96px;margin-top: 15px;margin-bottom: 0px;}
.p2{text-align: justify;padding-right: 96px;margin-top: 24px;margin-bottom: 0px;}
.p3{text-align: justify;padding-right: 96px;margin-top: 23px;margin-bottom: 0px;}
.p4{text-align: left;margin-top: 27px;margin-bottom: 0px;}
.p5{text-align: justify;padding-left: 24px;padding-right: 96px;margin-top: 26px;margin-bottom: 0px;text-indent: -24px;}
.p6{text-align: justify;padding-left: 24px;padding-right: 91px;margin-top: 6px;margin-bottom: 0px;text-indent: -24px;}
.p7{text-align: left;padding-left: 24px;padding-right: 96px;margin-top: 8px;margin-bottom: 0px;text-indent: -24px;}
.p8{text-align: left;padding-left: 24px;padding-right: 96px;margin-top: 9px;margin-bottom: 0px;text-indent: -24px;}
.p9{text-align: justify;padding-left: 24px;padding-right: 91px;margin-top: 9px;margin-bottom: 0px;text-indent: -24px;}
.p10{text-align: left;margin-top: 0px;margin-bottom: 0px;}
.p11{text-align: left;padding-left: 3px;margin-top: 0px;margin-bottom: 0px;}
.p12{text-align: left;margin-top: 8px;margin-bottom: 0px;}
.p13{text-align: left;margin-top: 7px;margin-bottom: 0px;}
.p14{text-align: left;padding-left: 24px;padding-right: 96px;margin-top: 8px;margin-bottom: 0px;}
.p15{text-align: justify;padding-left: 24px;padding-right: 96px;margin-top: 9px;margin-bottom: 0px;text-indent: -24px;}
.p16{text-align: left;margin-top: 6px;margin-bottom: 0px;}
.p17{text-align: left;padding-left: 53px;padding-right: 96px;margin-top: 8px;margin-bottom: 0px;text-indent: -29px;}
.p18{text-align: justify;padding-left: 53px;padding-right: 96px;margin-top: 9px;margin-bottom: 0px;text-indent: -29px;}
.p19{text-align: justify;padding-left: 53px;padding-right: 96px;margin-top: 8px;margin-bottom: 0px;text-indent: -29px;}
.p20{text-align: justify;padding-left: 53px;padding-right: 96px;margin-top: 6px;margin-bottom: 0px;text-indent: -29px;}
.p21{text-align: left;padding-left: 24px;margin-top: 7px;margin-bottom: 0px;}
.p22{text-align: left;padding-left: 48px;margin-top: 4px;margin-bottom: 0px;}
.p23{text-align: left;padding-left: 48px;margin-top: 7px;margin-bottom: 0px;}
.p24{text-align: justify;padding-left: 82px;padding-right: 91px;margin-top: 4px;margin-bottom: 0px;text-indent: -34px;}
.p25{text-align: justify;padding-left: 82px;padding-right: 91px;margin-top: 3px;margin-bottom: 0px;text-indent: -34px;}
.p26{text-align: left;padding-left: 82px;padding-right: 96px;margin-top: 0px;margin-bottom: 0px;}
.p27{text-align: left;padding-left: 82px;padding-right: 96px;margin-top: 5px;margin-bottom: 0px;text-indent: -34px;}
.p28{text-align: justify;padding-left: 82px;padding-right: 96px;margin-top: 0px;margin-bottom: 0px;text-indent: -34px;}
.p29{text-align: justify;padding-left: 82px;padding-right: 96px;margin-top: 4px;margin-bottom: 0px;text-indent: -34px;}
.p30{text-align: left;padding-left: 48px;margin-top: 6px;margin-bottom: 0px;}
.p31{text-align: left;padding-left: 82px;padding-right: 96px;margin-top: 7px;margin-bottom: 0px;text-indent: -34px;}
.p32{text-align: left;padding-left: 24px;margin-top: 8px;margin-bottom: 0px;}
.p33{text-align: justify;padding-left: 53px;padding-right: 96px;margin-top: 2px;margin-bottom: 0px;text-indent: -34px;}
.p34{text-align: justify;padding-left: 53px;padding-right: 91px;margin-top: 17px;margin-bottom: 0px;text-indent: -34px;}
.p35{text-align: left;padding-left: 48px;margin-top: 3px;margin-bottom: 0px;}
.p36{text-align: left;padding-left: 48px;margin-top: 8px;margin-bottom: 0px;}
.p37{text-align: justify;padding-left: 53px;padding-right: 96px;margin-top: 0px;margin-bottom: 0px;text-indent: -34px;}
.p38{text-align: left;padding-left: 53px;padding-right: 96px;margin-top: 8px;margin-bottom: 0px;text-indent: -34px;}
.p39{text-align: justify;padding-left: 53px;padding-right: 96px;margin-top: 9px;margin-bottom: 0px;text-indent: -34px;}
.p40{text-align: left;padding-left: 53px;padding-right: 96px;margin-top: 6px;margin-bottom: 0px;text-indent: -34px;}
.p41{text-align: left;padding-left: 53px;padding-right: 96px;margin-top: 10px;margin-bottom: 0px;text-indent: -34px;}
.p42{text-align: justify;padding-left: 53px;padding-right: 96px;margin-top: 7px;margin-bottom: 0px;text-indent: -34px;}
.p43{text-align: left;padding-left: 53px;padding-right: 96px;margin-top: 11px;margin-bottom: 0px;text-indent: -34px;}
.p44{text-align: justify;padding-left: 53px;padding-right: 96px;margin-top: 7px;margin-bottom: 0px;text-indent: -44px;}
.p45{text-align: justify;padding-left: 53px;padding-right: 96px;margin-top: 9px;margin-bottom: 0px;text-indent: -44px;}
.p46{text-align: justify;padding-left: 24px;padding-right: 96px;margin-top: 11px;margin-bottom: 0px;text-indent: -24px;}
.p47{text-align: left;padding-left: 165px;margin-top: 79px;margin-bottom: 0px;}
.p48{text-align: left;padding-left: 24px;padding-right: 96px;margin-top: 7px;margin-bottom: 0px;text-indent: -24px;}
.p49{text-align: justify;padding-left: 24px;padding-right: 91px;margin-top: 7px;margin-bottom: 0px;text-indent: -24px;}
.p50{text-align: justify;padding-left: 24px;padding-right: 96px;margin-top: 7px;margin-bottom: 0px;text-indent: -24px;}
.p51{text-align: justify;padding-left: 24px;padding-right: 96px;margin-top: 6px;margin-bottom: 0px;text-indent: -24px;}
.p52{text-align: left;padding-left: 24px;padding-right: 96px;margin-top: 0px;margin-bottom: 0px;text-indent: -24px;}
.p53{text-align: justify;padding-left: 24px;padding-right: 96px;margin-top: 4px;margin-bottom: 0px;text-indent: -24px;}
.p54{text-align: justify;padding-left: 24px;padding-right: 91px;margin-top: 12px;margin-bottom: 0px;text-indent: -24px;}
.p55{text-align: justify;padding-left: 24px;padding-right: 96px;margin-top: 1px;margin-bottom: 0px;text-indent: -24px;}
.p56{text-align: justify;padding-left: 24px;padding-right: 91px;margin-top: 14px;margin-bottom: 0px;text-indent: -24px;}
.p57{text-align: left;margin-top: 23px;margin-bottom: 0px;}
.p58{text-align: left;margin-top: 19px;margin-bottom: 0px;}
.p59{text-align: justify;padding-left: 24px;padding-right: 96px;margin-top: 20px;margin-bottom: 0px;text-indent: -24px;}
.p60{text-align: left;padding-left: 165px;margin-top: 164px;margin-bottom: 0px;}
.p61{text-align: left;padding-left: 24px;padding-right: 91px;margin-top: 20px;margin-bottom: 0px;text-indent: -24px;}
.p62{text-align: justify;padding-left: 24px;padding-right: 91px;margin-top: 11px;margin-bottom: 0px;text-indent: -24px;}
.p63{text-align: left;margin-top: 18px;margin-bottom: 0px;}
.p64{text-align: justify;padding-left: 24px;padding-right: 96px;margin-top: 16px;margin-bottom: 0px;text-indent: -24px;}
.p65{text-align: justify;padding-left: 24px;padding-right: 96px;margin-top: 19px;margin-bottom: 0px;text-indent: -24px;}
.p66{text-align: left;padding-left: 24px;padding-right: 96px;margin-top: 0px;margin-bottom: 0px;}
.p67{text-align: left;margin-top: 24px;margin-bottom: 0px;}
.p68{text-align: justify;padding-left: 24px;padding-right: 91px;margin-top: 16px;margin-bottom: 0px;text-indent: -24px;}
.p69{text-align: justify;padding-left: 24px;padding-right: 96px;margin-top: 13px;margin-bottom: 0px;text-indent: -24px;}
.p70{text-align: justify;padding-left: 24px;padding-right: 96px;margin-top: 14px;margin-bottom: 0px;text-indent: -24px;}
.p71{text-align: justify;padding-left: 24px;padding-right: 91px;margin-top: 13px;margin-bottom: 0px;text-indent: -24px;}
.p72{text-align: left;padding-left: 24px;padding-right: 96px;margin-top: 13px;margin-bottom: 0px;text-indent: -24px;}
.p73{text-align: justify;padding-left: 24px;padding-right: 96px;margin-top: 17px;margin-bottom: 0px;text-indent: -24px;}
.p74{text-align: justify;padding-left: 24px;padding-right: 91px;margin-top: 20px;margin-bottom: 0px;text-indent: -24px;}
.p75{text-align: justify;padding-left: 24px;padding-right: 91px;margin-top: 2px;margin-bottom: 0px;text-indent: -24px;}
.p76{text-align: left;margin-top: 29px;margin-bottom: 0px;}
.p77{text-align: justify;padding-left: 24px;padding-right: 91px;margin-top: 15px;margin-bottom: 0px;text-indent: -24px;}
.p78{text-align: justify;padding-left: 24px;padding-right: 91px;margin-top: 0px;margin-bottom: 0px;}
.p79{text-align: justify;padding-left: 24px;padding-right: 96px;margin-top: 10px;margin-bottom: 0px;}
.p80{text-align: left;margin-top: 30px;margin-bottom: 0px;}
.p81{text-align: left;margin-top: 25px;margin-bottom: 0px;}
.p82{text-align: justify;padding-left: 24px;padding-right: 96px;margin-top: 15px;margin-bottom: 0px;text-indent: -24px;}
.p83{text-align: left;margin-top: 28px;margin-bottom: 0px;}
.p84{text-align: justify;padding-left: 24px;padding-right: 96px;margin-top: 0px;margin-bottom: 0px;}
.p85{text-align: left;margin-top: 21px;margin-bottom: 0px;}
.p86{text-align: left;padding-left: 24px;padding-right: 91px;margin-top: 7px;margin-bottom: 0px;text-indent: -24px;}
.p87{text-align: left;margin-top: 22px;margin-bottom: 0px;}
.p88{text-align: left;padding-left: 24px;padding-right: 96px;margin-top: 19px;margin-bottom: 0px;text-indent: -24px;}
.p89{text-align: left;margin-top: 58px;margin-bottom: 0px;}

